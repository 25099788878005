export const RoadmapData = [
  {
    track: "roadmap-track1.png",
    unlocked: true,
    prev: true,
    mc: "1 MeLLi",
    chapter: "1",
    one: "Pooti chapter 1",
    two: "PFP gen 2.0",
    three: "Meme contest",
    four: "coingecko listing",
    five: "Pooti game",
    url: "pooti-sw.png",
  },
  {
    track: "roadmap-track2.png",
    unlocked: false,
    prev: true,
    mc: "5 mEil",
    chapter: "2",
    one: "Pooti chapter 2",
    two: "PFP Gen 3.0",
    three: "$20k+ Marketing Campaign/Cex listings",
    four: "Pooti holder Airdrop",
    five: "Website update",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track3.png",
    unlocked: false,
    prev: true,
    mc: "10 mEaL",
    chapter: "3",
    one: "Pooti chapter 3",
    two: "$50k+ Marketing Campaign/Cex listings",
    three: "Major KOL partnerships",
    four: "Scavenger Hunt",

    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track4.png",
    unlocked: false,
    prev: false,
    mc: "25 MEel",
    chapter: "1",
    // one: "Pooti chapter 4",
    // two: "KOL Expansion",
    // three: "List on more CEX/DEX",
    // four: "Pooti Giveaway",
    // five: "Pooti Airdrop",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track5.png",
    unlocked: false,
    prev: false,
    mc: "50 meL",
    chapter: "1",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track6.png",
    unlocked: false,
    prev: false,
    mc: "100 MIel",
    chapter: "1",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track7.png",
    unlocked: false,
    prev: false,
    mc: "200 MaL",
    chapter: "1",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track8.png",
    unlocked: false,
    prev: false,
    mc: "500 MEloN",
    chapter: "1",
    url: "pooti-hammer.png",
  },
  {
    track: "roadmap-track9.png",
    unlocked: false,
    prev: false,
    mc: "1 BelLions",
    chapter: "1",
    url: "pooti-hammer.png",
  },
];
