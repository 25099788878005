import "./FrontPage.scss";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FrontPage = ({ about, buy, ScrollToSection }) => {
  return (
    <>
      <div className="front-page-bg-container">
        <div className="floating-img3">
          <img src={require("../../media/transparent/bubble.png")} alt="" />
        </div>
        <div className="navbar">
          <div className="nav-list">
            <ul>
              <li>
                <Link to="/story">Me StoRY</Link>
              </li>
              <li>
                <a onClick={() => ScrollToSection(about)} href="javascript:;">
                  AbOut
                </a>
              </li>
              <li>
                <a onClick={() => ScrollToSection(buy)} href="javascript:;">
                  HoW BUy
                </a>
              </li>
              <li>
                <Link to="/memes">MeMs</Link>
              </li>
              {/* <li>
                <a href="/">Blog</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="floating-img">
          <div className="floating-img1">
            <img src={require("../../media/transparent/pooti-2.png")} alt="" />
          </div>
          <div className="floating-img2">
            <img src={require("../../media/transparent/pooti-1.png")} alt="" />
          </div>

          {/* <div className="floating-img3">
          <img src={require("../../media/transparent/POOTI-6.png")} alt="" />
        </div> */}
        </div>
        <div className="front-page-content-container">
          {/* <div className="front-page-img">
          <img src={require("../../media/POOTI-j.png")} alt="" />
        </div> */}
          <div className="front-page-info">
            <div className="title">$PoOTi</div>
            <div className="buy-now-btn">
              <a
                href="https://jup.ag/swap/SOL-POOTI"
                target="_blank"
                rel="noreferrer"
              >
                BuY Mee nOw
              </a>
            </div>
            <div className="socials">
              <div className="social">
                <a className="icon" href="https://x.com/Pootikat">
                  <FaXTwitter size={22} />
                </a>
                <a className="icon mobile" href="https://x.com/Pootikat">
                  <FaXTwitter size={16} />
                </a>
              </div>
              <div className="social">
                <a className="icon" href="https://t.me/pootiportal">
                  <FaTelegramPlane size={22} />
                </a>
                <a className="icon mobile" href="https://t.me/pootiportal">
                  <FaTelegramPlane size={16} />
                </a>
              </div>
              <div className="social">
                <a className="icon" href="https://discord.gg/NQVnfNz9tz">
                  <FaDiscord size={22} />
                </a>
                <a className="icon mobile" href="https://discord.gg/NQVnfNz9tz">
                  <FaDiscord size={16} />
                </a>
              </div>
              <div className="social">
                <a
                  className="icon"
                  href="https://www.tiktok.com/@dylang626?lang=en"
                >
                  <FaTiktok size={22} />
                </a>
                <a
                  className="icon mobile"
                  href="https://www.tiktok.com/@dylang626?lang=en"
                >
                  <FaTiktok size={16} />
                </a>
              </div>
              <div className="social">
                <a className="icon" href="https://www.instagram.com/dylang626/">
                  <FaInstagram size={22} />
                </a>
                <a
                  className="icon mobile"
                  href="https://www.instagram.com/dylang626/"
                >
                  <FaInstagram size={16} />
                </a>
              </div>
              <div className="social">
                <a className="icon" href="https://www.youtube.com/@Dylang626">
                  <FaYoutube size={22} />
                </a>
                <a
                  className="icon mobile"
                  href="https://www.youtube.com/@Dylang626"
                >
                  <FaYoutube size={16} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="marquee">
        <p>
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI
          &middot; $PoOTi &middot; $PoOTi &nbsp;
        </p>
        <p>
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
          &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi&nbsp;
        </p>
      </div>
    </>
  );
};
export default FrontPage;
