import "./Jupiter.scss";

const JupiterTerminal = ({ buy }) => {
  return (
    <div className="jupiter-container" ref={buy}>
      <div className="how-to">
        <div className="title">BUy $PoOTi</div>
        <div className="tut-grid-container">
          <div className="tut-box box1">
            <div className="number">1</div>
            <div className="box-title">MaKe WaLleT</div>
            <div className="description">
              GEt SoLaNa waLleT like PhaNTom deN MaKe WaLleT.
            </div>
          </div>
          <div className="tut-box box2">
            <div className="number">2</div>
            <div className="box-title">BuY SOlaNa</div>
            <div className="description">
              BUy SoLAna frEm PhaNTom Or CoiNBase den SEnd SOL tO Solana waLlet
              adDRess.
            </div>
          </div>
          <div className="tut-box box3">
            <div className="number">3</div>
            <div className="box-title">FinD XchaNge</div>
            <div className="description">
              Go tO DeX like JuPIter oR PhOtON den ConNect WaLlEt, It WOrk?
            </div>
          </div>
          <div className="tut-box box4">
            <div className="number">4</div>
            <div className="box-title">BuY PoOTi ( oR ElSe 🔫 )</div>
            <div className="description">
              SeRch fOR Me PoOTi. SwaP SoLaNA Fer mE. SRsLy BuY mE NoW;
            </div>
          </div>
          <div className="buy-img1">
            <img src={require("../../media/transparent/pooti-j.png")} alt="" />
          </div>
          <div className="buy-img2">
            <img
              src={require("../../media/transparent/pooti-creep.webp")}
              alt=""
            />
          </div>
          {/* <div className="buy-img3">
            <img src={require("../../media/transparent/pooti-4.png")} alt="" />
          </div> */}
          {/* <div className="buy-img4">
            <img
              src={require("../../media/transparent/pooti-4-rev.png")}
              alt=""
            />
          </div> */}
        </div>
        {/* <div className="ca">
          CA: 2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn
        </div>
        <div className="listings">
          <div className="listing listing1 icon">
            <a
              href="https://dexscreener.com/solana/ht8v4t8litda5jj1jjdyfuw8c4tywgy7zc2cl3719cpx"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../media/dex-screener-logo.png")} alt="" />
            </a>
          </div>
          <div className="listing listing2 icon">
            <a
              href="https://www.coingecko.com/en/coins/pooti-relaunch"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../media/CoinGecko_logo.png")} alt="" />
            </a>
          </div>
        </div> */}
      </div>
      {/* <div className="buy-vid">
        <video
          autoPlay
          muted
          loop
          src={require("../../media/videos/pooti-tutorial.mp4")}
          alt=""
        />
      </div> */}
      {/* <div className="bottom-img">
        <img src={require("../../media/transparent/pooti-park.png")} alt="" />
      </div> */}
    </div>
  );
};

export default JupiterTerminal;
