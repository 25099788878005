import useMarketData from "./UseMarketData";
import "./style.scss";
const MarketCap = () => {
  const { marketCap, error } = useMarketData();

  if (error) return <div>{error}</div>;
  if (marketCap === null) return <div>Loading...</div>;

  return (
    <div className="data-container">
      <div className="label"> Market Cap:</div>{" "}
      <div className="value">${marketCap.toLocaleString()}</div>
    </div>
  );
};

export default MarketCap;
