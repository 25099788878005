import "./Disclaimer.scss";

const Disclaimer = () => {
  return (
    <div className="disc-container">
      <div className="disc-content-container">
        <div className="title">$PoOTi</div>
        <div className="ca">
          <div className="ca-img">
            <img
              src={require("../../media/transparent/pooti-hammer.png")}
              alt=""
            />
          </div>
          CA: 2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn
        </div>
        <div className="listings">
          <div className="listing listing1 icon">
            <a
              href="https://dexscreener.com/solana/ht8v4t8litda5jj1jjdyfuw8c4tywgy7zc2cl3719cpx"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../media/logos/dex-screener-logo.png")}
                alt=""
              />
            </a>
          </div>
          <div className="listing listing2 icon">
            <a
              href="https://www.coingecko.com/en/coins/pooti-relaunch"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("../../media/logos/CoinGecko_logo.png")}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="disc-disc">
          <div className="disc-txt">
            DISCLAiMer: $PooTi CaT iS a meMEcoiN wiTh nO inTRiNsic vaLue. dOn'T
            risK monEY yOU aRe aFraid oF losINg. thE pRiCe maY go uP, or iT
            miGht juSt dO tHe boOGie doWn. wE arE nOT reSponSibLe fOR thE prIce
            oF tHe tOKen.
          </div>
          <div className="disc-copyright">&copy; PoOTi 2024</div>
        </div>
      </div>
    </div>
  );
};
export default Disclaimer;
