import { RoadmapData } from "./RoadmapData";
import "./Roadmap.scss";
import { Link } from "react-router-dom";

const Roadmap = () => {
  return (
    <div className="roadmap-bg-container">
      <div className="roadmap-title">
        <div className="main-roadmap-title">RaCe T0 $1 PeSOs</div>
        <div className="main-roadmap-description">
          FlIp CaRD To SeE PoOTi StoRy. UnLoCK New SnEk Pek AfTEr PRev GOal
          AchIEve
        </div>
      </div>
      <div className="roadmap-content-container">
        {RoadmapData.map((data, key) => (
          <div className="racetrack">
            <div className="title">{data.mc}</div>
            <div className={data.prev === true ? `track unlocked` : `track`}>
              <div className="track-box">
                {/* <img
                  src={require("../../media/Roadmap-track/roadmap-square.png")}
                  alt=""
                /> */}
                <div className="track-assets">
                  <div className="track-front">
                    <img
                      className="track-img"
                      src={require(`../../media/Roadmap-track/${data.track}`)}
                      alt=""
                    />
                    {data.unlocked ? (
                      <div className="trophy">
                        <img
                          src={require(`../../media/Roadmap-track/roadmap-trophy-unlocked.png`)}
                          alt=""
                        />
                      </div>
                    ) : (
                      <>
                        <div className="lock">
                          <img
                            src={require(`../../media/Roadmap-track/roadmap-lock.png`)}
                            alt=""
                          />
                        </div>
                        <div className="trophy">
                          <img
                            src={require(`../../media/Roadmap-track/roadmap-trophy-vlocked.png`)}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="track-back">
                  {/* {data.unlocked ? (
                    <div></div>
                  ) : (
                    <p>HelP mE GeT dIS tAnG OpEn</p>
                  )} */}
                  <ul>
                    <li> - {data.one}</li>
                    <li> - {data.two}</li>
                    <li> - {data.three}</li>
                    <li> - {data.four}</li>
                    {data.five ? <li> - {data.five} </li> : ""}
                    {data.six ? <li> - {data.six} </li> : ""}
                  </ul>
                  {/* <img
                    src={require(`../../media/transparent/${data.url}`)}
                    alt=""
                  /> */}
                  {/* {!data.unlocked && (
                    <img
                      className="locked-img"
                      src={require(`../../media/Roadmap-track/roadmap-lock.png`)}
                      alt=""
                    />
                  )} */}
                  {data.unlocked && (
                    <Link to="/story">Watch Chapter {data.chapter}</Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="roadmap-img1">
          <video
            autoPlay
            loop
            playsInline
            muted
            src={require("../../media/videos/drive.mp4")}
            alt=""
          />
        </div>
        <div className="roadmap-img2">
          <video
            autoPlay
            loop
            playsInline
            muted
            src={require("../../media/Roadmap-track/gameplay.mp4")}
            alt=""
          />
          <a
            className="game-link"
            href="https://apps.apple.com/us/app/pooti/id6502561141"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("../../media/transparent/game-link.png")}
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="game-container">
        <div className="game-text">PLaY mI GAmE NoW (ThIS Is ThREat)</div>
        <div className="game-btn">
          <a
            href="https://apps.apple.com/us/app/pooti/id6502561141"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            PLey NoW
          </a>
        </div>
      </div>
    </div>
  );
};
export default Roadmap;
