import "./Pooti.scss";

const Pooti = () => {
  return (
    <div class="marquee2">
      <p>
        &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI &middot;
        $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $POOTI &middot; $PoOTi
        &middot; $PoOTi &middot; $PoOTi &middot; $POOTI &middot; $PoOTi &middot;
        $PoOTi &middot; $PoOTi &middot; $POOTI &middot; $PoOTi &middot; $PoOTi
        &middot; $PoOTi &middot; $POOTI &middot; $PoOTi &middot; $PoOTi &middot;
        $PoOTi &middot; $POOTI &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
        &middot; $POOTI &middot; $PoOTi &middot; $PoOTi &nbsp;
      </p>
      <p>
        &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot;
        $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
        &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot;
        $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
        &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot;
        $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi
        &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot; $PoOTi &middot;
        $PoOTi&nbsp;
      </p>
    </div>
  );
};
export default Pooti;
