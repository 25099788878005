import { useState, useEffect } from "react";

const useMarketData = () => {
  const [marketCap, setMarketCap] = useState(null);
  const [price, setPrice] = useState(null);
  const [volume, setVolume] = useState(null);
  const [error, setError] = useState(null);

  const coinId = "2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn";

  const fetchMarketData = async () => {
    try {
      const response = await fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${coinId}`
      );
      const data = await response.json();
      const marketCapValue = data.pairs[0].fdv;
      const priceValue = data.pairs[0].priceUsd;
      const volumeValue = data.pairs[0].volume.h24;
      setMarketCap(marketCapValue);
      setPrice(Math.round(priceValue));
      setVolume(Math.round(volumeValue));
    } catch (error) {
      setError("Error loading market data");
      console.error("Error fetching market data:", error);
    }
  };

  useEffect(() => {
    fetchMarketData();
    const interval = setInterval(fetchMarketData, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, [coinId]);

  return { marketCap, price, volume, error };
};

export default useMarketData;

// import React, { useEffect, useState } from "react";

// const MarketCap = () => {
//   const [marketCap, setMarketCap] = useState(null);
//   const [price, setPrice] = useState(null); // [1
//   const [error, setError] = useState(null);

//   const coinId = "2nDpiBboQgHcBuRyNwCQzc5fcpxBZKQ1t37pr5aNFczn";

//   const fetchMarketCap = async () => {
//     try {
//       const response = await fetch(
//         `https://api.dexscreener.com/latest/dex/tokens/${coinId}`
//       );
//       const data = await response.json();
//       const marketCapValue = data.pairs[0].fdv;
//       const priceValue = data.pairs[0].price; // [2
//       setMarketCap(marketCapValue);
//     } catch (error) {
//       setError("Error loading market cap");
//       console.error("Error fetching market cap:", error);
//     }
//   };

//   useEffect(() => {
//     fetchMarketCap();
//     const interval = setInterval(fetchMarketCap, 60000); // Refresh every 60 seconds
//     return () => clearInterval(interval);
//   }, [coinId]);

//   return (
//     <div>
//       {console.log(marketCap)}
//       <MarketCap marketCap={marketCap} />
//     </div>
//   );
// };

// export default MarketCap;
