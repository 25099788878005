import "./Army.scss";

const Army = () => {
  return (
    <div className="promo-bg-container">
      <div className="promo-content-container">
        <div className="promo-img-section">
          <img src={require("../../media/transparent/pooti-ufo.jpg")} alt="" />
        </div>
        <div className="promo-text-section">
          <div className="promo-title">Join the $Pooti Army</div>

          <div className="promo-text">
            GEt A $PoOTi prOFiLe pICtuRe nOw bY uSiNg oUR PfP geNEraTer
          </div>
          <a href="https://pootipfpgenerator.com/">PfP geNEraTer</a>
        </div>
      </div>
      <div className="pooti-gang">
        <div className="pooti-gang-txt">
          <div className="pooti-gang-title">
            JoiN PoOTi aNd DylanG to UnLoCK MeMEs anD StOrieS
          </div>
          <div className="pooti-gang-description">
            <div className="pg line1">
              The community team and Dylan G. are all-in on creating wild video
              and meme content for every $POOTI mcap milestone. What's next?
            </div>
            <div className="pg line2">
              Maybe Pooti and Pepe plotting shenanigans, Pooti in Counter-Strike
              doing the ninja-defuse, Pooti and Popcat poping their mouths, or
              Pooti and Wojak go down and dirty.
            </div>
            <div className="pg line3">
              Who knows? And if you degens are into it, holders are going to be
              rewarded with some NFTs. Stay tuned, because everyone’s got a
              story, and we value every single one of our PoOTi community’s
              tales.
            </div>
          </div>
        </div>
        <div className="pooti-gang-img">
          <div class="outerBevel">
            <div class="flatSurface">
              <div class="innerBevel">
                <img
                  src={require("../../media/transparent/pooti-gang.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="line"></div> */}
    </div>
  );
};
export default Army;
