import Army from "../../components/Army/Army";
// import Dylan from "../../components/Dylan/Dylan";
import FrontPage from "../../components/FrontPage.jsx/FrontPage";
import JupiterTerminal from "../../components/Jupiter/Jupiter";
// import Promo from "../../components/Promo/Promo";
import Roadmap from "../../components/Roadmap/Roadmap";
// import MarketCap from "../../components/MarketCap";
import TopPage from "../../components/TopPage/TopPage";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Story from "../../components/Story/Story";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import Community from "../../components/Community/Community";
import Pooti from "../../components/Pooti/Pooti";
import { useRef } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import "./Home.scss";

function Home() {
  const about = useRef(null);
  const buy = useRef(null);

  const ScrollToSection = elementRef => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="App">
      <ScrollToTop />
      <div className="front-page">
        <TopPage />
        <FrontPage about={about} buy={buy} ScrollToSection={ScrollToSection} />
      </div>
      <Story about={about} />
      <Army />
      <Roadmap />
      {/* <Map /> */}
      <Tokenomics />
      <Community />
      <Pooti />
      <JupiterTerminal buy={buy} />
      {/* <About /> */}
      {/* <Dylan /> */}
      {/* <Promo /> */}
      <Disclaimer />
    </div>
  );
}

export default Home;
