export const MemeData = [
  { url: "pooti-1.png", idx: 1 },

  { url: "pooti-2.png", idx: 2 },

  { url: "pooti-3.png", idx: 3 },

  { url: "pooti-4.png", idx: 4 },

  { url: "pooti-5.png", idx: 5 },

  { url: "pooti-6.png", idx: 6 },

  { url: "pooti-7.png", idx: 7 },

  { url: "pooti-8.png", idx: 8 },

  { url: "pooti-9.png", idx: 9 },

  { url: "pooti-10.png", idx: 10 },

  { url: "pooti-11.png", idx: 11 },

  { url: "pooti-12.png", idx: 12 },

  { url: "pooti-13.png", idx: 13 },

  { url: "pooti-14.png", idx: 14 },

  { url: "pooti-15.png", idx: 15 },

  { url: "pooti-16.png", idx: 16 },

  { url: "pooti-17.png", idx: 17 },

  { url: "pooti-18.png", idx: 18 },

  { url: "pooti-19.png", idx: 19 },

  { url: "pooti-20.png", idx: 20 },

  { url: "pooti-21.png", idx: 21 },

  { url: "pooti-22.png", idx: 22 },

  { url: "pooti-23.png", idx: 23 },

  { url: "pooti-24.png", idx: 24 },

  { url: "pooti-25.png", idx: 25 },

  { url: "pooti-26.png", idx: 26 },

  { url: "pooti-27.png", idx: 27 },

  { url: "pooti-28.png", idx: 28 },

  { url: "pooti-29.png", idx: 29 },

  { url: "pooti-30.png", idx: 30 },

  { url: "pooti-31.png", idx: 31 },

  { url: "pooti-32.png", idx: 32 },

  { url: "pooti-33.png", idx: 33 },

  { url: "pooti-34.png", idx: 34 },

  { url: "pooti-35.png", idx: 35 },

  { url: "pooti-36.png", idx: 36 },

  { url: "pooti-37.png", idx: 37 },

  { url: "pooti-38.png", idx: 38 },

  { url: "pooti-39.png", idx: 39 },

  { url: "pooti-40.png", idx: 40 },

  { url: "pooti-41.png", idx: 41 },

  { url: "pooti-42.png", idx: 42 },

  { url: "pooti-43.png", idx: 43 },

  { url: "pooti-44.png", idx: 44 },

  { url: "pooti-45.png", idx: 45 },

  { url: "pooti-46.png", idx: 46 },

  { url: "pooti-47.png", idx: 47 },

  { url: "pooti-48.png", idx: 48 },

  { url: "pooti-49.png", idx: 49 },

  { url: "pooti-50.png", idx: 50 },

  { url: "pooti-51.png", idx: 51 },

  { url: "pooti-52.png", idx: 52 },

  { url: "pooti-53.png", idx: 53 },

  { url: "pooti-54.png", idx: 54 },

  { url: "pooti-55.png", idx: 55 },

  { url: "pooti-56.png", idx: 56 },

  { url: "pooti-57.png", idx: 57 },

  { url: "pooti-58.png", idx: 58 },

  { url: "pooti-59.png", idx: 59 },

  { url: "pooti-60.png", idx: 60 },

  { url: "pooti-61.png", idx: 61 },

  { url: "pooti-62.png", idx: 62 },

  { url: "pooti-63.png", idx: 63 },

  { url: "pooti-64.png", idx: 64 },

  { url: "pooti-65.png", idx: 65 },

  { url: "pooti-66.png", idx: 66 },

  { url: "pooti-67.png", idx: 67 },

  { url: "pooti-68.png", idx: 68 },

  { url: "pooti-69.png", idx: 69 },

  { url: "pooti-70.png", idx: 70 },

  { url: "pooti-126.png", idx: 71 },

  { url: "pooti-72.png", idx: 72 },

  { url: "pooti-73.png", idx: 73 },

  { url: "pooti-74.png", idx: 74 },

  { url: "pooti-75.png", idx: 75 },

  { url: "pooti-76.png", idx: 76 },

  { url: "pooti-77.png", idx: 77 },

  { url: "pooti-78.png", idx: 78 },

  { url: "pooti-79.png", idx: 79 },

  { url: "pooti-80.png", idx: 80 },

  { url: "pooti-81.png", idx: 81 },

  { url: "pooti-82.png", idx: 82 },

  { url: "pooti-83.png", idx: 83 },

  { url: "pooti-84.png", idx: 84 },

  { url: "pooti-85.png", idx: 85 },

  { url: "pooti-86.png", idx: 86 },

  { url: "pooti-87.png", idx: 87 },

  { url: "pooti-88.png", idx: 88 },

  { url: "pooti-89.png", idx: 89 },

  { url: "pooti-90.png", idx: 90 },

  { url: "pooti-91.png", idx: 91 },

  { url: "pooti-92.png", idx: 92 },

  { url: "pooti-93.png", idx: 93 },

  { url: "pooti-94.png", idx: 94 },

  { url: "pooti-95.png", idx: 95 },

  { url: "pooti-96.png", idx: 96 },

  { url: "pooti-97.png", idx: 97 },

  { url: "pooti-98.png", idx: 98 },

  { url: "pooti-99.png", idx: 99 },

  { url: "pooti-100.png", idx: 100 },

  { url: "pooti-101.png", idx: 101 },

  { url: "pooti-102.png", idx: 102 },

  { url: "pooti-103.png", idx: 103 },

  { url: "pooti-104.png", idx: 104 },

  { url: "pooti-105.png", idx: 105 },

  { url: "pooti-106.png", idx: 106 },

  { url: "pooti-107.png", idx: 107 },

  { url: "pooti-108.png", idx: 108 },

  { url: "pooti-121.png", idx: 109 },

  { url: "pooti-110.png", idx: 110 },

  { url: "pooti-111.png", idx: 111 },

  { url: "pooti-112.png", idx: 112 },

  { url: "pooti-113.png", idx: 113 },

  { url: "pooti-114.png", idx: 114 },

  { url: "pooti-115.png", idx: 115 },

  { url: "pooti-116.png", idx: 116 },

  { url: "pooti-117.png", idx: 117 },

  { url: "pooti-118.png", idx: 118 },

  { url: "pooti-119.png", idx: 119 },

  { url: "pooti-120.png", idx: 120 },

  { url: "pooti-109.png", idx: 121 },

  { url: "pooti-122.png", idx: 122 },

  { url: "pooti-123.png", idx: 123 },

  { url: "pooti-124.png", idx: 124 },

  { url: "pooti-125.png", idx: 125 },

  { url: "pooti-126.png", idx: 126 },

  { url: "pooti-127.png", idx: 127 },

  { url: "pooti-128.png", idx: 128 },

  { url: "pooti-129.png", idx: 129 },

  { url: "pooti-130.png", idx: 130 },

  { url: "pooti-131.png", idx: 131 },

  { url: "pooti-132.png", idx: 132 },

  { url: "pooti-133.png", idx: 133 },

  { url: "pooti-134.png", idx: 134 },

  { url: "pooti-135.png", idx: 135 },

  { url: "pooti-136.png", idx: 136 },

  { url: "pooti-137.png", idx: 137 },

  { url: "pooti-138.png", idx: 138 },

  { url: "pooti-139.png", idx: 139 },

  { url: "pooti-140.png", idx: 140 },

  { url: "pooti-141.png", idx: 141 },

  { url: "pooti-142.png", idx: 142 },

  { url: "pooti-143.png", idx: 143 },

  { url: "pooti-144.png", idx: 144 },

  { url: "pooti-145.png", idx: 145 },

  { url: "pooti-146.png", idx: 146 },

  { url: "pooti-147.png", idx: 147 },

  { url: "pooti-148.png", idx: 148 },

  { url: "pooti-149.png", idx: 149 },

  { url: "pooti-150.png", idx: 150 },

  { url: "pooti-151.png", idx: 151 },

  { url: "pooti-152.png", idx: 152 },

  { url: "pooti-153.png", idx: 153 },

  { url: "pooti-154.png", idx: 154 },

  { url: "pooti-155.png", idx: 155 },

  { url: "pooti-156.png", idx: 156 },

  { url: "pooti-157.png", idx: 157 },

  { url: "pooti-158.png", idx: 158 },

  { url: "pooti-159.png", idx: 159 },

  { url: "pooti-160.png", idx: 160 },

  { url: "pooti-161.png", idx: 161 },

  { url: "pooti-162.png", idx: 162 },

  { url: "pooti-163.png", idx: 163 },

  { url: "pooti-164.png", idx: 164 },

  { url: "pooti-165.png", idx: 165 },

  { url: "pooti-166.png", idx: 166 },

  { url: "pooti-167.png", idx: 167 },

  { url: "pooti-168.png", idx: 168 },

  { url: "pooti-169.png", idx: 169 },

  { url: "pooti-170.png", idx: 170 },

  { url: "pooti-171.png", idx: 171 },

  { url: "pooti-172.png", idx: 172 },

  { url: "pooti-173.png", idx: 173 },

  { url: "pooti-174.png", idx: 174 },

  { url: "pooti-175.png", idx: 175 },
  { url: "pooti-176.png", idx: 176 },
  { url: "pooti-177.png", idx: 177 },
  { url: "pooti-178.png", idx: 178 },
  { url: "pooti-179.png", idx: 179 },
  { url: "pooti-180.png", idx: 180 },
  { url: "pooti-181.png", idx: 181 },
  { url: "pooti-182.png", idx: 182 },
  { url: "pooti-183.png", idx: 183 },
  { url: "pooti-184.png", idx: 184 },
  { url: "pooti-185.png", idx: 185 },
  { url: "pooti-186.png", idx: 186 },
  { url: "pooti-187.png", idx: 187 },
  // { url: "pooti-7.png", idx: 176 },
];

export const VidData = [
  { url: "pooti-vid-1.mp4", idx: 1 },

  { url: "pooti-vid-2.mp4", idx: 2 },

  { url: "pooti-vid-3.mp4", idx: 3 },

  { url: "pooti-vid-4.mp4", idx: 4 },

  { url: "pooti-vid-5.mp4", idx: 5 },

  { url: "pooti-vid-6.mp4", idx: 6 },

  { url: "pooti-vid-7.mp4", idx: 7 },

  { url: "pooti-vid-8.mp4", idx: 8 },

  { url: "pooti-vid-9.mp4", idx: 9 },

  { url: "pooti-vid-10.mp4", idx: 10 },

  { url: "pooti-vid-11.mp4", idx: 11 },

  { url: "pooti-vid-12.mp4", idx: 12 },

  { url: "pooti-vid-13.mp4", idx: 13 },

  { url: "pooti-vid-14.mp4", idx: 14 },

  { url: "pooti-vid-15.mp4", idx: 15 },

  { url: "pooti-vid-16.mp4", idx: 16 },

  { url: "pooti-vid-17.mp4", idx: 17 },

  { url: "pooti-vid-18.mp4", idx: 18 },

  { url: "pooti-vid-19.mp4", idx: 19 },

  { url: "pooti-vid-20.mp4", idx: 20 },

  { url: "pooti-vid-21.mp4", idx: 21 },

  { url: "pooti-vid-22.mp4", idx: 22 },

  { url: "pooti-vid-23.mp4", idx: 23 },

  { url: "pooti-vid-24.mp4", idx: 24 },

  { url: "pooti-vid-25.mp4", idx: 25 },

  { url: "pooti-vid-26.mp4", idx: 26 },

  { url: "pooti-vid-27.mp4", idx: 27 },

  { url: "pooti-vid-28.mp4", idx: 28 },

  { url: "pooti-vid-29.mp4", idx: 29 },

  { url: "pooti-vid-30.mp4", idx: 30 },

  { url: "pooti-vid-31.mp4", idx: 31 },

  { url: "pooti-vid-32.mp4", idx: 32 },

  { url: "pooti-vid-33.mp4", idx: 33 },

  { url: "pooti-vid-34.mp4", idx: 34 },

  { url: "pooti-vid-35.mp4", idx: 35 },

  { url: "pooti-vid-36.mp4", idx: 36 },

  { url: "pooti-vid-37.mp4", idx: 37 },

  { url: "pooti-vid-38.mp4", idx: 38 },

  { url: "pooti-vid-39.mp4", idx: 39 },

  { url: "pooti-vid-40.mp4", idx: 40 },

  { url: "pooti-vid-41.mp4", idx: 41 },

  { url: "pooti-vid-42.mp4", idx: 42 },

  { url: "pooti-vid-43.mp4", idx: 43 },

  { url: "pooti-vid-44.mp4", idx: 44 },

  { url: "pooti-vid-45.mp4", idx: 45 },

  { url: "pooti-vid-46.mp4", idx: 46 },

  { url: "pooti-vid-47.mp4", idx: 47 },

  { url: "pooti-vid-48.mp4", idx: 48 },

  { url: "pooti-vid-49.mp4", idx: 49 },

  { url: "pooti-vid-50.mp4", idx: 50 },

  { url: "pooti-vid-51.mp4", idx: 51 },

  { url: "pooti-vid-52.mp4", idx: 52 },

  { url: "pooti-vid-53.mp4", idx: 53 },

  { url: "pooti-vid-54.mp4", idx: 54 },

  { url: "pooti-vid-55.mp4", idx: 55 },

  { url: "pooti-vid-56.mp4", idx: 56 },

  { url: "pooti-vid-57.mp4", idx: 57 },

  { url: "pooti-vid-58.mp4", idx: 58 },

  { url: "pooti-vid-59.mp4", idx: 59 },

  { url: "pooti-vid-60.mp4", idx: 60 },

  { url: "pooti-vid-61.mp4", idx: 61 },

  { url: "pooti-vid-62.mp4", idx: 62 },

  { url: "pooti-vid-63.mp4", idx: 63 },

  { url: "pooti-vid-64.mp4", idx: 64 },

  { url: "pooti-vid-65.mp4", idx: 65 },

  { url: "pooti-vid-66.mp4", idx: 66 },

  { url: "pooti-vid-67.mp4", idx: 67 },

  { url: "pooti-vid-68.mp4", idx: 68 },

  { url: "pooti-vid-69.mp4", idx: 69 },

  { url: "pooti-vid-70.mp4", idx: 70 },

  { url: "pooti-vid-71.mp4", idx: 71 },

  { url: "pooti-vid-72.mp4", idx: 72 },

  { url: "pooti-vid-73.mp4", idx: 73 },

  { url: "pooti-vid-74.mp4", idx: 74 },

  { url: "pooti-vid-75.mp4", idx: 75 },

  { url: "pooti-vid-76.mp4", idx: 76 },

  { url: "pooti-vid-77.mp4", idx: 77 },

  { url: "pooti-vid-78.mp4", idx: 78 },

  { url: "pooti-vid-79.mp4", idx: 79 },

  { url: "pooti-vid-80.mp4", idx: 80 },

  { url: "pooti-vid-81.mp4", idx: 81 },

  { url: "pooti-vid-82.mp4", idx: 82 },

  { url: "pooti-vid-83.mp4", idx: 83 },

  { url: "pooti-vid-84.mp4", idx: 84 },

  { url: "pooti-vid-85.mp4", idx: 85 },

  { url: "pooti-vid-86.mp4", idx: 86 },

  { url: "pooti-vid-87.mp4", idx: 87 },

  { url: "pooti-vid-88.mp4", idx: 88 },

  { url: "pooti-vid-89.mp4", idx: 89 },

  { url: "pooti-vid-90.mp4", idx: 90 },

  { url: "pooti-vid-91.mp4", idx: 91 },

  { url: "pooti-vid-92.mp4", idx: 92 },

  { url: "pooti-vid-93.mp4", idx: 93 },

  { url: "pooti-vid-94.mp4", idx: 94 },

  { url: "pooti-vid-95.mp4", idx: 95 },

  { url: "pooti-vid-96.mp4", idx: 96 },

  { url: "pooti-vid-97.mp4", idx: 97 },

  { url: "pooti-vid-98.mp4", idx: 98 },

  { url: "pooti-vid-99.mp4", idx: 99 },
  { url: "pooti-vid-100.mp4", idx: 100 },

  { url: "pooti-vid-101.mp4", idx: 101 },

  { url: "pooti-vid-102.mp4", idx: 102 },

  { url: "pooti-vid-103.mp4", idx: 103 },

  { url: "pooti-vid-104.mp4", idx: 104 },

  { url: "pooti-vid-105.mp4", idx: 105 },

  { url: "pooti-vid-106.mp4", idx: 106 },

  { url: "pooti-vid-107.mp4", idx: 107 },

  { url: "pooti-vid-108.mp4", idx: 108 },

  { url: "pooti-vid-109.mp4", idx: 109 },

  { url: "pooti-vid-110.mp4", idx: 110 },

  { url: "pooti-vid-111.mp4", idx: 111 },

  { url: "pooti-vid-112.mp4", idx: 112 },

  { url: "pooti-vid-113.mp4", idx: 113 },

  { url: "pooti-vid-114.mp4", idx: 114 },

  { url: "pooti-vid-115.mp4", idx: 115 },

  { url: "pooti-vid-116.mp4", idx: 116 },

  { url: "pooti-vid-117.mp4", idx: 117 },

  { url: "pooti-vid-118.mp4", idx: 118 },

  { url: "pooti-vid-119.mp4", idx: 119 },

  { url: "pooti-vid-120.mp4", idx: 120 },

  { url: "pooti-vid-121.mp4", idx: 121 },

  { url: "pooti-vid-122.mp4", idx: 122 },

  { url: "pooti-vid-123.mp4", idx: 123 },

  { url: "pooti-vid-124.mp4", idx: 124 },

  { url: "pooti-vid-125.mp4", idx: 125 },

  { url: "pooti-vid-126.mp4", idx: 126 },

  { url: "pooti-vid-127.mp4", idx: 127 },

  { url: "pooti-vid-128.mp4", idx: 128 },

  { url: "pooti-vid-129.mp4", idx: 129 },

  { url: "pooti-vid-130.mp4", idx: 130 },

  { url: "pooti-vid-131.mp4", idx: 131 },

  { url: "pooti-vid-132.mp4", idx: 132 },

  { url: "pooti-vid-133.mp4", idx: 133 },

  { url: "pooti-vid-134.mp4", idx: 134 },

  { url: "pooti-vid-135.mp4", idx: 135 },

  { url: "pooti-vid-136.mp4", idx: 136 },

  { url: "pooti-vid-137.mp4", idx: 137 },

  { url: "pooti-vid-138.mp4", idx: 138 },

  { url: "pooti-vid-139.mp4", idx: 139 },

  { url: "pooti-vid-140.mp4", idx: 140 },
];
