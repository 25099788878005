import MarketCap from "../Price/MarketCp";
import Volume from "../Price/Volume";
import "./TopPage.scss";

const TopPage = () => {
  return (
    <div className="top-page-container width">
      <div className="top-page-content-container">
        <div className="price-container">
          <div className="market-cap">
            <MarketCap />
          </div>
          <div className="price">
            <Volume />
          </div>
        </div>
        <div className="buy-btn-container">
          <a
            href="https://jup.ag/swap/SOL-POOTI"
            target="_blank"
            rel="noreferrer"
            className="buy-btn"
          >
            Buy $pooti
          </a>
        </div>
      </div>
    </div>
  );
};
export default TopPage;
