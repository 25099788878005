import Meme from "../../meme-components/meme-home/Meme";

const MemePage = () => {
  return (
    <div>
      <Meme />
    </div>
  );
};
export default MemePage;
