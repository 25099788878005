import useMarketData from "./UseMarketData";

const Volume = ({ coinId }) => {
  const { volume, error } = useMarketData(coinId);

  if (error) return <div>{error}</div>;
  if (volume === null) return <div>Loading...</div>;

  return (
    <div className="data-container">
      <div className="label">Volume (24h)</div>{" "}
      <div className="value">${volume.toLocaleString()}</div>
    </div>
  );
};

export default Volume;
