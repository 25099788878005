import "./App.scss";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Meme from "./Pages/MemePage/MemePage";
import Story from "./Pages/Story/Story/Story";

function App() {
  return (
    <Router>
      <Routes>
        {/* <div className="App"> */}
        <Route path="/" element={<Home />} />
        <Route path="/memes" element={<Meme />} />
        <Route path="/story" element={<Story />} />

        {/* </div> */}
      </Routes>
    </Router>
  );
}

export default App;
