import { Link } from "react-router-dom";
import TopPage from "../../../components/TopPage/TopPage";
import "./Story.scss";
// import { StoryData } from "../StoryData";

const Story = ({ about }) => {
  return (
    <div className="story-container" ref={about}>
      <TopPage />
      <div className="story-bg-container">
        <div className="story-nav">
          <div className="nav-list">
            <ul>
              <li>
                <Link to="/">HoMe</Link>
              </li>
              <li>
                <a href="javascript:;">ChAPteR 1</a>
              </li>
              <li className="c2">
                <a href="javascript:;">ChaPTer 2</a>
              </li>
              <li>
                <Link to="/memes">MeMs</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="chapter1-container">
          {/* <div className="chapter1-story">
            {StoryData.map((data, key) => (
              <div className={`c1-p1 story-box ${data.class}`}>
                <div className="p1-text story-text">
                  <div className="title">{data.title}</div>
                  {data.story}
                </div>
                <div className="p1-vid story-vid">
                  <video
                    controls
                    // src={require(`../../../media/story/${data.video}`)}
                  ></video>
                </div>
              </div>
            ))}
          </div> */}
          <div className="c1-p3 story-box">
            <div className="p3-text story-text">
              <div className="title">Stay Tuned For Chapter 1 release!</div>
              {/* {data.story} */}
            </div>
            {/* <div className="p1-vid story-vid">
              <video
                controls
                // src={require(`../../../media/story/${data.video}`)}
              ></video>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Story;
