import "./Community.scss";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const Community = () => {
  return (
    <div className="com-bg-container">
      <div className="com-content-container">
        <div className="com-txt-container">
          <div className="com-title">thE PoOTi cOmmUNity is bAnaNAs</div>
          <div className="com-txt">
            WelCoMe mY stRaY kiTtieS to thE CoMmuNity. PooTi will feEd u BanAnas
            in tEleGraM, DiSCord and on twitTeR X.
          </div>
          <div className="com-links">
            <div className="social">
              <a className="icon" href="https://t.me/pootiportal">
                <FaTelegramPlane size={22} />
              </a>
            </div>
            <div className="social">
              <a className="icon" href="https://discord.gg/xf5XugKF">
                <FaDiscord size={22} />
              </a>
            </div>
            <div className="social">
              <a className="icon" href="https://x.com/Pootikat">
                <FaXTwitter size={22} />
              </a>
            </div>
          </div>
        </div>
        <div className="com-img-container">
          <img src={require("../../media/transparent/banana.png")} alt="" />
        </div>
      </div>
    </div>
  );
};
export default Community;
