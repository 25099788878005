import "./Story.scss";

const Story = ({ about }) => {
  return (
    <div className="lore-bg-container" ref={about}>
      <div className="lore-content-container ">
        <div className="title">The Legend of PoOTi (RELAUNCH)</div>
        <div className="lore-container lore-box1">
          <div className="floating-lore img1">
            <img src={require("../../media/transparent/pooti-8.png")} alt="" />
          </div>
          {/* <div className="lore-box">
            <div className="lore-story-box"> */}
          <div className="lore-txt-container">
            <div className="lore-txt">
              <div className="lore-txt-title">
                thE ReLaUnch aNd OuR LeGeNdAry dEv
              </div>
              <div className="lore-txt-des">
                <div className="line">
                  SoOOOooo, $POOTI launched on May 8th on @pumpdotfun and
                  quickly built a core following because of its unique and truly
                  fun vibe. The ticker says (RELAUNCH), which usually is a red
                  flag, but not for $POOTI.{" "}
                </div>
                <div className="line">
                  After launching, our dev's Telegram got hacked, leading to the
                  first relaunch. Then, he bought ads for the wrong version of
                  the coin.{" "}
                </div>
                <div className="line">
                  Finally, he tried to burn 1-2% of the supply but ended up
                  burning his entire holdings. These mishaps endeared him to the
                  community, showing he couldn’t rug them if he tried!{" "}
                </div>
                <div className="line">dEV RugGED hiMSelF??? WomP WomP :( </div>
              </div>
            </div>
          </div>
          <div className="lore-img">
            <img src={require("../../media/lore/pooti-creep.jpeg")} alt="" />
            {/* </div>
            </div> */}
          </div>
        </div>
        <div className="lore-container lore-box2">
          <div className="floating-lore img2 point">
            <img src={require("../../media/lore/pooti-point.png")} alt="" />
          </div>
          <div className="floating-lore img3 point">
            <img src={require("../../media/lore/perchi-point.png")} alt="" />
          </div>
          <div className="floating-lore-alone img5">
            <img src={require("../../media/lore/pooti-scorpion.png")} alt="" />
          </div>
          <div className="floating-lore-alone img6">
            <img src={require("../../media/lore/ansem.png")} alt="" />
          </div>
          <div className="floating-lore img7">
            <img src={require("../../media/lore/cow.png")} alt="" />
          </div>
          {/* <div className="lore-box">
            <div className="lore-story-box"> */}
          <div className="lore-txt-container">
            <div className="lore-txt">
              <div className="lore-txt-title">
                Perchi’s Alien Abduction and Pooti’s Rise
              </div>
              <div className="lore-txt-des s">
                <div className="line">
                  Perchi, the original meme cat, was abducted by aliens and
                  cloned, giving birth to Pooti. Unlike Perchi, who was already
                  IG/TikTok famous, Pooti wanted his own identity. <br></br>
                </div>
                <div className="line">
                  He joined crypto Twitter, started shitposting, and followed
                  top holders and KOLs. Pooti then launched his own coin, and in
                  a twist of fate, rugged himself as the chart kept going up,
                  turning him into an iconic figure in the crypto world.
                </div>
              </div>
            </div>
          </div>
          <div className="lore-vid">
            <video
              autoPlay
              playsInline
              muted
              src={require("../../media/videos/ufo.mp4")}
              alt=""
            />
          </div>
          {/* </div>
          </div> */}
        </div>
        <div className="lore-container lore-box3">
          {/* <div className="lore-box">
            <div className="lore-story-box"> */}
          <div className="floating-lore img4">
            <img src={require("../../media/lore/dylan.png")} alt="" />
          </div>
          <div className="lore-txt-container">
            <div className="lore-txt">
              <div className="lore-txt-title">
                DylanG - thE OriGiNAL G for PoOTi
              </div>
              <div className="lore-txt-des s">
                <div className="line">
                  Five years ago, Argentinian artist and cartoonist Dylan G.
                  brought Perchi to life, a character now loved by over 1M fans.
                  Fast forward to 2020, stuck at home during the COVID-19
                  quarantine, Dylan started sharing his cartoons online. On June
                  26, 2020, Perchita was born.{" "}
                </div>
                <div className="line">
                  We’re beyond proud to have Dylan on board as a compensated
                  artist. Thanks to the $Pooti community, Dylan was able to
                  ditch his old gig and work on Perchi and Pooti full-time. He’s
                  using his first paycheck to buy himself a bike—how
                  ridiculously wholesome is that?
                </div>
              </div>
            </div>
          </div>
          <div className="lore-img">
            <img src={require("../../media/lore/sleep.png")} alt="" />
          </div>
          {/* </div>
          </div> */}
        </div>
        <div className="buttons">
          <div className="btn1">
            <a href="https://linktr.ee/dylang626">foLLoW Dylan</a>
          </div>
          <div className="btn2">
            <a href="https://ko-fi.com/dylang626">bUY Him CoffeE </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Story;
