import { useState } from "react";
import TopPage from "../../components/TopPage/TopPage";
import "./Meme.scss";
import { MemeData } from "../MemeData";
import {
  IoMdArrowRoundForward,
  IoMdArrowRoundBack,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { Link } from "react-router-dom";

const Meme = () => {
  const [enlargedImgId, setEnlargedImgId] = useState(null);
  const [currentImgIndex, setCurrentImgIndex] = useState(1);
  // const [enlargedVidId, setEnlargedVidId] = useState(null);
  // const [currentVidIndex, setCurrentVidIndex] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // const toggleEnlarge = id => {
  //   setEnlargedImgId(id === enlargedImgId ? null : id);
  // };

  const goToNext = () => {
    if (currentImgIndex < MemeData.length - 1) {
      setCurrentImgIndex(currentImgIndex + 1);
      //   setEnlargedImgId(null); // Reset enlarged image when navigating to the next
    } else {
      setCurrentImgIndex(0);
    }
  };
  const goBack = () => {
    if (currentImgIndex > 0) {
      setCurrentImgIndex(currentImgIndex - 1);
      //   setEnlargedImgId(null); // Reset enlarged image when navigating to the next
    } else {
      setCurrentImgIndex(MemeData.length - 1);
    }
  };

  return (
    <div className="meme-page-container">
      <div className="top-page">
        <TopPage />
      </div>
      <div className="meme-page-bg-container">
        <div className="meme-navbar">
          <div className="nav-list">
            <ul>
              <li>
                <Link to="/" onclick={() => setCurrentPage(1)}>
                  HoME
                </Link>
              </li>
              <li>
                <div className="link" onClick={() => setCurrentPage(1)}>
                  PiCTurEs
                </div>
              </li>
              <li>
                <div className="link" onClick={() => setCurrentPage(2)}>
                  {" "}
                  ViDeOS
                </div>
              </li>
              {/* <li>
                <a href="/">RaNdOm</a>
              </li> */}
              {/* <li>
                <a href="/">Blog</a>
              </li> */}
            </ul>
          </div>
        </div>

        {currentPage === 1 && (
          <div className="pictures-tab">
            {console.log(currentPage)}

            <div className="pictures-container">
              <div className="meme-grid-container">
                {enlargedImgId && (
                  <div className="background">
                    <div
                      className="close "
                      onClick={() => setEnlargedImgId(null)}
                    >
                      <IoIosCloseCircleOutline size={50} />
                    </div>
                    <div className="arrow right" onClick={goToNext}>
                      <IoMdArrowRoundForward />
                    </div>
                    <div className="arrow left" onClick={goBack}>
                      <IoMdArrowRoundBack />
                    </div>

                    <img
                      className="enlarged-img"
                      onClick={() => setEnlargedImgId(null)}
                      src={require(`../../media/pooti-meme-pics/${MemeData[currentImgIndex].url}`)}
                      alt=""
                    />
                  </div>
                )}
                <div className="meme-card">
                  <div className="meme-img">
                    <div
                      className={enlargedImgId !== null && "overlay"}
                      onClick={() => setEnlargedImgId(null)}
                    ></div>
                    {MemeData.map((meme, key) => {
                      return (
                        <div className="img-cover">
                          <img
                            className="image-container"
                            onClick={() => {
                              setCurrentImgIndex(meme.idx - 1);
                              setEnlargedImgId(meme.url);
                            }}
                            src={require(`../../media/pooti-meme-pics/${meme.url}`)}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="pooti-pic-link">
                <p>CliCk HeRE foR aLL thE PoOTi mEmEs!</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/drive/folders/1EZYwUFPaM-rdYA2teJZJLPotqm45axcp"
                >
                  gALlerY
                </a>
              </div>
            </div>
          </div>
        )}
        {currentPage === 2 && (
          <div className="videos-tab">
            <div className="vid-container">
              <div className="tv-container">
                <img src={require("../../media/pooti-vid/tv3.png")} alt="" />
                <div className="vid-bg-container">
                  {/* <div className="warning-txt">
                    ViDEo No PLaY ProPErLy On MoBiLe, BuT IsOk HeRE iS LInk FoR
                    AlL MeME{" "}
                    <a href="https://drive.google.com/drive/folders/1HmVDosT26DCKBVpoig98v1XuVN40wMF_">
                      dOCs
                    </a>
                    <a href="https://drive.google.com/drive/folders/1HmVDosT26DCKBVpoig98v1XuVN40wMF_">
                      MeMe Tg
                    </a>
                  </div> */}
                  <video
                    // controls
                    playsInline
                    autoPlay
                    muted
                    preload="metadata"
                    className="vid-container"
                    // onClick={() => {
                    //   setCurrentVidIndex(meme.idx - 1);
                    //   setEnlargedVidId(meme.url);
                    // }}
                    src={require(`../../media/pooti-vid/pooti-vid-12.mp4#t=0.001`)}
                    alt=""
                  />
                  <div className="vid-contained">
                    {/* <div
                      className={enlargedVidId !== null && "vid-overlay"}
                      onClick={() => setEnlargedVidId(null)}
                    ></div> */}
                    {/* {VidData.map((meme, key) => {
                      return (
                        <div className="vid-cover">
                          <video
                            // controls
                            // playsInline
                            preload="metadata"
                            className="vid-container"
                            onClick={() => {
                              setCurrentVidIndex(meme.idx - 1);
                              setEnlargedVidId(meme.url);
                            }}
                            src={require(`../../media/pooti-vid/${meme.url}#t=0.001`)}
                            alt=""
                          />
                        </div>
                      );
                    })} */}
                  </div>
                </div>
                <div className="vid-meme-floating img1">
                  <img
                    src={require(`../../media/transparent/pooti-4-rev.png`)}
                    alt=""
                  />
                </div>
                <div className="vid-meme-floating img2">
                  <img
                    src={require(`../../media/transparent/pooti-4.png`)}
                    alt=""
                  />
                </div>
              </div>
              <div className="pooti-vid-link">
                <p>CliCk HeRE foR aLL thE PoOTi ViDEos!</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/drive/folders/1EZYwUFPaM-rdYA2teJZJLPotqm45axcp"
                >
                  gALlerY
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Meme;
