import "./Tokenomics.scss";

const Tokenomics = () => {
  return (
    <div className="tokenomics-bg-container">
      <div className="tokenomics-title">
        <div className="main-tokenomics-title">PoOTiNomiCS</div>
        {/* <div className="main-tokenomics-description">
          FlIp CaRD TeW SeE PrIZes
        </div> */}
      </div>
      <div className="tokenomics-content-container">
        <div className="tokenomics-img-container">
          <img
            className="tok-img"
            src={require("../../media/transparent/tokenomics6.png")}
            alt=""
          />
          <div className="tokenomics-num num1">
            <div className="num">94</div>
            <div className="text">CuMmUNiTy</div>
          </div>
          <div className="tokenomics-num num2">
            <div className="text">bUrNT</div>
            <div className="num">4</div>
          </div>
          <div className="tokenomics-num num3">
            <div className="text">MerKETing</div>
            <div className="num">2</div>
          </div>
          <div className="token-center">
            <div className="tokenomics-img1">
              <img
                src={require("../../media/transparent/pooti-logo.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="tok-info">
          {/* <div className="tok-elem">
            <img src={require("../../media/transparent/pooti-5.png")} alt="" />
            <div className="tok-box box1">
              <div className="box-title">
                {" "}
                <div className="top">
                  <span>1,000,000,000</span> toKeN
                </div>
                <div className="bottom">965,000,000</div>
              </div>
            </div>
          </div> */}
          <div className="tok-elem off">
            <img
              src={require("../../media/transparent/pooti-fire.png")}
              alt=""
            />
            <div className="tok-box box2">
              <div className="box-title">
                LiQUiDIty <span>EaT</span> BurNT
              </div>
            </div>
          </div>
          <div className="tok-elem ">
            <img
              src={require("../../media/transparent/pooti-mcd.png")}
              alt=""
            />
            <div className="tok-box box3">
              <div className="box-title">0% TAx</div>
            </div>
          </div>
          <div className="tok-elem">
            <img
              src={require("../../media/transparent/pooti-ball.png")}
              alt=""
            />
            <div className="tok-box box4">
              <div className="box-title">MInt & FreEze rEvOkEd</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="tokenomics-img2">
        <img src={require("../../media/transparent/nirvana.png")} alt="" />
      </div>
      <div className="tokenomics-img3">
        <img src={require("../../media/transparent/pooti-3.png")} alt="" />
      </div> */}
      {/* <div className="tokenomics-img img3">
        <video
          loop
          autoPlay
          muted
          playsInline
          src={require("../../media/videos/fire.mp4")}
          alt=""
        />
      </div>
      <div className="tokenomics-img img2">
        <video
          loop
          autoPlay
          muted
          playsInline
          src={require("../../media/videos/dance.mp4")}
          alt=""
        />
      </div> */}
    </div>
  );
};
export default Tokenomics;
